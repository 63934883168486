<template>
    <div class="wmax-90 w-100-ps grow-custom-1 relative-pos flex-box flexcol ai-center jc-center">
        <div class="w-100 flex-box flexcol ai-center jc-center margin-bottom-1 padding-top-1">
            <div class="w-100 flexnone-box flexrow ai-center jc-between padding-top-1">
                <div class="w-100 flex-box flexrow ai-center">
                    <i class="bi bi-1-circle h1 text-blue-07"></i>
                    <div class="w-100 text-warning padding-left-1 padding-right-1"><hr style="border-width: 1px; border-style: solid" /></div>
                </div>
                <div class="w-100 flex-box flexrow ai-center">
                    <i class="bi bi-2-circle h1 text-blue-07"></i>
                    <div class="w-100 text-warning padding-left-1 padding-right-1"><hr style="border-width: 1px; border-style: solid" /></div>
                </div>
                <div class="w-100 flex-box flexrow ai-center">
                    <i class="bi bi-3-circle h1 text-blue-07"></i>
                    <div class="w-100 text-warning padding-left-1 padding-right-1"><hr style="border-width: 1px; border-style: solid" /></div>
                </div>
                <div class="flex-box flexrow ai-center">
                    <i class="bi bi-stop-circle h1 text-danger"></i>
                </div>
            </div>
            <div class="w-100 flexnone-box flexrow jc-between padding-bottom-1">
                <div class="w-100 flex-box flexrow" style="padding-left: 15px">
                    <i class="bi bi-arrow-return-right h4 padding-right-05 text-warning"></i>
                    <div class="w-100 flex-box flexcol" style="padding-right: 30px">
                        <div class="flex-box flexcol">
                            <template v-if="langchoose === 'id'">
                                <h4>Apa yang dapat kami bantu&nbsp;?</h4>
                                <div  class="text-secondary">
                                    Materi kursus yang kami berikan sesuai permintaan klien.
                                </div>
                                <div  class="text-secondary">
                                    (Bisa tugas sekolah, kuliah, ataupun topik bebas).
                                </div>
                            </template>
                            <template v-else>
                                <h4>What should we help you&nbsp;?</h4>
                                <div  class="text-secondary">
                                    The subjects we tutor are upon your request.
                                </div>
                                <div  class="text-secondary">
                                    (Such as school homeworks, college tasks, or whatever the projects)
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="w-100 flex-box flexrow" style="margin-left: -20px">
                    <i class="bi bi-arrow-return-right h4 padding-right-05 text-warning"></i>
                    <div class="w-100 flex-box flexcol" style="padding-right: 30px">
                        <div class="flex-box flexcol">
                            <template v-if="langchoose === 'id'">
                                <h4>Daftar Kursus</h4>
                            </template>
                            <template v-else>
                                <h4>Register the Course</h4>
                            </template>
                            <div @click="chatAdmin(contactperson)" class="text-secondary flex-box flexrow ai-center padding-top-05 handCursor">
                                <i class="bi bi-whatsapp margin-right-05"></i>
                                <span class="padding-right-05">
                                    {{ contactperson }}
                                </span>
                                <span class="text-secondary">(chat only)</span>
                            </div>
                            <div @click="chatAdmin(contactperson02)" class="text-secondary flex-box flexrow ai-center padding-top-05 padding-bottom-05 handCursor">
                                <i class="bi bi-whatsapp margin-right-05"></i>
                                <span class="padding-right-05">
                                    {{ contactperson02 }}
                                </span>
                                <span class="text-secondary">(chat only)</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-100 flex-box flexrow" style="margin-left: -25px">
                    <i class="bi bi-arrow-return-right h4 padding-right-05 text-warning"></i>
                    <div class="w-100 flex-box flexcol" style="padding-right: 35px">
                        <div class="flex-box flexcol">
                            <template v-if="langchoose === 'id'">
                                <h4>Kursus</h4>
                            </template>
                            <template v-else>
                                <h4>Courses</h4>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-100 noneflex-box flexcol">
                <div class="flex-box flexrow padding-top-1">
                    <div class="flex-box flexcol ai-center" style="width: 75px">
                        <i class="bi bi-1-circle h1 text-blue-07"></i>
                        <div class="h-100-ps bg-warning" style="max-width: 2px">&nbsp;
                        </div>
                    </div>
                    <div class="w-100-ps flex-box flexcol">
                        <template v-if="langchoose === 'id'">
                            <h4>Apa yang dapat kami bantu&nbsp;?</h4>
                            <div  class="text-secondary">
                                Materi kursus yang kita berikan sesuai permintaan klien.
                            </div>
                            <div  class="text-secondary">
                                (Bisa tugas sekolah, kuliah, ataupun topik bebas).
                            </div>
                        </template>
                        <template v-else>
                            <h4>What should we help you&nbsp;?</h4>
                            <div  class="text-secondary">
                                The subjects we tutor are upon your request.
                            </div>
                            <div  class="text-secondary">
                                (Such as school homeworks, college tasks, or whatever the projects)
                            </div>
                        </template>
                        <div>&nbsp;</div>
                    </div>
                </div>
                <div class="flex-box flexrow padding-top-1">
                    <div class="flex-box flexcol ai-center" style="width: 75px">
                        <i class="bi bi-2-circle h1 text-blue-07"></i>
                        <div class="h-100-ps bg-warning" style="max-width: 2px">&nbsp;
                        </div>
                    </div>
                    <div class="w-100-ps flex-box flexcol">
                        <template v-if="langchoose === 'id'">
                            <h4>Daftar Kursus</h4>
                        </template>
                        <template v-else>
                            <h4>Register the Course</h4>
                        </template>
                        <div @click="chatAdmin(contactperson)" class="text-secondary flex-box flexrow ai-center padding-top-05 handCursor">
                            <i class="bi bi-whatsapp margin-right-05"></i>
                            <span class="padding-right-05">
                                {{ contactperson }}
                            </span>
                            <span class="text-secondary">(chat only)</span>
                        </div>
                        <div @click="chatAdmin(contactperson02)" class="text-secondary flex-box flexrow ai-center padding-top-05 padding-bottom-05 handCursor">
                            <i class="bi bi-whatsapp margin-right-05"></i>
                            <span class="padding-right-05">
                                {{ contactperson02 }}
                            </span>
                            <span class="text-secondary">(chat only)</span>
                        </div>
                        <div>&nbsp;</div>
                    </div>
                </div>
                <div class="flex-box flexrow padding-top-1 padding-bottom-05">
                    <div class="flex-box flexcol ai-center" style="width: 75px">
                        <i class="bi bi-3-circle h1 text-blue-07"></i>
                        <div class="h-100-ps bg-warning" style="max-width: 2px">&nbsp;
                        </div>
                    </div>
                    <div class="w-100-ps flex-box flexcol">
                        <template v-if="langchoose === 'id'">
                            <h4>Kursus</h4>
                        </template>
                        <template v-else>
                            <h4>Course</h4>
                        </template>
                        <div>&nbsp;</div>
                    </div>
                </div>
                <div class="flex-box flexrow">
                    <div class="flex-box flexcol ai-center" style="width: 75px">
                        <i class="bi bi-stop-circle h1 text-danger"></i>
                    </div>
                    <div class="w-100-ps flex-box flexcol">&nbsp;
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'ScheduleComponent',
  components: 
  {
  },
  data() {
      return {
        lang : localStorage.getItem('lang'),
        langchoose : '',
        contactperson : '+6283167836090',
        contactperson02 : '+6285702607484',
      }
  },
  mounted() {
    if(this.lang != 'en') {
        this.langchoose = 'id'
    }
    else
    {
        this.langchoose = 'en'
    }
  },
  methods : {
    chatAdmin(wanumber){
        let chatadmin = "https://wa.me/"+wanumber
        window.open(chatadmin, '_blank')
    },
  }        
}
</script>

<style scoped>
    .wrap-border-blue-dark {
        background: linear-gradient(rgba(41, 98, 168, 1), rgba(255, 255, 255, 1));
    }  
    .wrap-border-red-dark {
        background: linear-gradient(rgba(41, 98, 168, 1), rgba(255, 98, 168, 1));
    }  
</style>