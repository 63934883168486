<template>
    <div class="wmax-90 w-100-ps grow-custom-1 relative-pos flex-box flexcol ai-center jc-center">
        <div class="w-100 flex-box flexcol ai-center jc-center margin-bottom-1">
            <div class="flex-box flexrow">
                <template v-if="langchoose === 'id'">
                    <h5 class="text-secondary text-center">
                        Details
                    </h5>
                </template>
                <template v-else>
                    <h5 class="text-secondary text-center">
                        Details
                    </h5>
                </template>
            </div>
        </div>
        <div class="w-100 flex-box flexcol ai-center jc-center margin-bottom-1">
            <div class="w-100 flex-box flexrow-col wrap-content-yes">
                <!--begin-->
                <div class="w-25-ps flex-box flexcol padding-custom-1">
                    <div class="clr-blue-07  
                                    grow-custom-1 
                                    w-100 
                                    flex-box flexcol 
                                    ai-center jc-center 
                                    padding-left-010
                                    padding-right-010
                                    padding-top-010
                                    padding-bottom-1
                                    rounded-custom-25">
                        <div class="bs22-white grow-custom-1 padding-custom-1 w-100 flex-box flexcol rounded-custom-25">
                            <div class="w-100 margin-bottom-1 flex-box flexcol">
                                <h4 class="font-bold text-secondary letterspce15">
                                    <template v-if="langchoose === 'id'">
                                        Matematika, Bahasa Inggris, & Lainnya
                                    </template>
                                    <template v-else>
                                        Math, English, etc
                                    </template>
                                </h4>
                            </div>
                            <div class="margin-bottom-05 flex-box flexcol font10rem text-secondary">
                                <ul class="padding-left-0 style-list-none">
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05 text-secondary"></i>
                                        <template v-if="langchoose === 'id'">
                                            SD, SMP, & SMA
                                        </template>
                                        <template v-else>
                                            Elementary & High School
                                        </template>
                                    </li>                                    
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05 text-secondary"></i>
                                        <template v-if="langchoose === 'id'">
                                            Pembelajaran didasarkan atas materi yang ingin 
                                            siswa pelajari.
                                        </template>
                                        <template v-else>
                                            Studying Subjects based on your request.
                                        </template>
                                    </li>
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05 text-secondary"></i>
                                        <template v-if="langchoose === 'id'">
                                            Tentor akan memperdalam pemahaman siswa akan
                                            materi tersebut.
                                        </template>
                                        <template v-else>
                                            Tutor will elevate student's knowledge about the subject
                                        </template>
                                    </li>
                                </ul>
                            </div>
                            <div class="margin-top-auto flex-box flexcol">
                                <span class="font10rem font-bold text-secondary">
                                    <template v-if="langchoose === 'id'">
                                        Biaya *
                                    </template>
                                    <template v-else>
                                        Price *
                                    </template>
                                </span>
                                <span class="margin-top-1 font10rem font-italic text-secondary">
                                    <template v-if="langchoose === 'id'">
                                        *) Sesuai kesepakatan.
                                    </template>
                                    <template v-else>
                                        *) Negotiated
                                    </template>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end-->
                <!--begin-->
                <div class="w-25-ps flex-box flexcol padding-custom-1">
                    <div class="clr-blue-07  
                                    grow-custom-1 
                                    w-100 
                                    flex-box flexcol 
                                    ai-center jc-center 
                                    padding-left-010
                                    padding-right-010
                                    padding-top-010
                                    padding-bottom-1
                                    rounded-custom-25">
                        <div class="bs22-white grow-custom-1 padding-custom-1 w-100 flex-box flexcol rounded-custom-25">
                            <div class="w-100 margin-bottom-1 flex-box flexcol">
                                <h4 class="font-bold text-secondary letterspce15">
                                    <template v-if="langchoose === 'id'">
                                        Komputer Dasar
                                    </template>
                                    <template v-else>
                                        Basic computer
                                    </template>
                                </h4>
                            </div>
                            <div class="margin-bottom-05 flex-box flexcol font10rem text-secondary">
                                <ul class="padding-left-0 style-list-none">
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05 text-secondary"></i>
                                        <template v-if="langchoose === 'id'">
                                            Microsoft Windows
                                        </template>
                                        <template v-else>
                                            Microsoft Windows
                                        </template>
                                    </li>
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05 text-secondary"></i>
                                        <template v-if="langchoose === 'id'">
                                            Microsoft Word
                                        </template>
                                        <template v-else>
                                            Microsoft Word
                                        </template>
                                    </li>
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05 text-secondary"></i>
                                        <template v-if="langchoose === 'id'">
                                            Microsoft Excell
                                        </template>
                                        <template v-else>
                                            Microsoft Excell
                                        </template>
                                    </li>
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05 text-secondary"></i>
                                        <template v-if="langchoose === 'id'">
                                            Microsoft Power Point
                                        </template>
                                        <template v-else>
                                            Microsoft Power Point
                                        </template>
                                    </li>
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05 text-secondary"></i>
                                        <template v-if="langchoose === 'id'">
                                            Internet (Browsing, & Email)
                                        </template>
                                        <template v-else>
                                            Internet (Browsing, & Email)
                                        </template>
                                    </li>
                                </ul>
                            </div>
                            <div class="margin-top-auto flex-box flexcol">
                                <span class="font10rem font-bold text-secondary">
                                    <template v-if="langchoose === 'id'">
                                        Biaya *
                                    </template>
                                    <template v-else>
                                        Price *
                                    </template>
                                </span>
                                <span class="margin-top-1 font10rem font-italic text-secondary">
                                    <template v-if="langchoose === 'id'">
                                        *) Sesuai kesepakatan.
                                    </template>
                                    <template v-else>
                                        *) Negotiated
                                    </template>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end-->
                <!--begin-->
                <div class="w-25-ps flex-box flexcol padding-custom-1">
                    <div class="clr-blue-07  
                                    grow-custom-1 
                                    w-100 
                                    flex-box flexcol 
                                    ai-center jc-center 
                                    padding-left-010
                                    padding-right-010
                                    padding-top-010
                                    padding-bottom-1
                                    rounded-custom-25">
                        <div class="bs22-white grow-custom-1 padding-custom-1 w-100 flex-box flexcol rounded-custom-25">
                            <div class="w-100 margin-bottom-1 flex-box flexcol">
                                <h4 class="font-bold text-secondary letterspce15">
                                    <template v-if="langchoose === 'id'">
                                        Frontend
                                    </template>
                                    <template v-else>
                                        Frontend
                                    </template>
                                </h4>
                            </div>
                            <div class="margin-bottom-05 flex-box flexcol font10rem text-secondary">
                                <ul class="padding-left-0 style-list-none">
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05 text-secondary"></i>
                                        <template v-if="langchoose === 'id'">
                                            HTML, CSS
                                        </template>
                                        <template v-else>
                                            HTML, CSS
                                        </template>
                                    </li>
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05 text-secondary"></i>
                                        <template v-if="langchoose === 'id'">
                                            Bootstrap
                                        </template>
                                        <template v-else>
                                            Bootstrap
                                        </template>
                                    </li>
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05 text-secondary"></i>
                                        <template v-if="langchoose === 'id'">
                                            VueJS
                                        </template>
                                        <template v-else>
                                            VueJS
                                        </template>
                                    </li>
                                </ul>
                            </div>
                            <div class="margin-top-auto flex-box flexcol">
                                <span class="font10rem font-bold text-secondary">
                                    <template v-if="langchoose === 'id'">
                                        Gratis *
                                    </template>
                                    <template v-else>
                                        Free *
                                    </template>
                                </span>
                                <span class="margin-top-1 font10rem font-italic text-secondary">
                                    <template v-if="langchoose === 'id'">
                                        *) Syarat ketentuan berlaku.
                                    </template>
                                    <template v-else>
                                        *) Term and conditions applied.
                                    </template>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end-->
                <!--begin-->
                <div class="w-25-ps flex-box flexcol padding-custom-1">
                    <div class="clr-blue-07  
                                    grow-custom-1 
                                    w-100 
                                    flex-box flexcol 
                                    ai-center jc-center 
                                    padding-left-010
                                    padding-right-010
                                    padding-top-010
                                    padding-bottom-1
                                    rounded-custom-25">
                        <div class="bs22-white grow-custom-1 padding-custom-1 w-100 flex-box flexcol rounded-custom-25">
                            <div class="w-100 margin-bottom-1 flex-box flexcol">
                                <h4 class="font-bold text-secondary letterspce15">
                                    <template v-if="langchoose === 'id'">
                                        Backend
                                    </template>
                                    <template v-else>
                                        Backend
                                    </template>
                                </h4>
                            </div>
                            <div class="margin-bottom-05 flex-box flexcol font10rem text-secondary">
                                <ul class="padding-left-0 style-list-none">
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05 text-secondary"></i>
                                        <template v-if="langchoose === 'id'">
                                            PHP
                                        </template>
                                        <template v-else>
                                            PHP
                                        </template>
                                    </li>
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05 text-secondary"></i>
                                        <template v-if="langchoose === 'id'">
                                            MySQL
                                        </template>
                                        <template v-else>
                                            MySQL
                                        </template>
                                    </li>
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05 text-secondary"></i>
                                        <template v-if="langchoose === 'id'">
                                            Laravel, Lumen
                                        </template>
                                        <template v-else>
                                            Laravel, Lumen
                                        </template>
                                    </li>
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05 text-secondary"></i>
                                        <template v-if="langchoose === 'id'">
                                            CRUD (Create, Read, Update, dan Delete)
                                        </template>
                                        <template v-else>
                                            CRUD (Create, Read, Update, and Delete)
                                        </template>
                                    </li>
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05 text-secondary"></i>
                                        <template v-if="langchoose === 'id'">
                                            RESTful API
                                        </template>
                                        <template v-else>
                                            RESTful API
                                        </template>
                                    </li>
                                </ul>
                            </div>
                            <div class="margin-top-auto flex-box flexcol">
                                <span class="font10rem font-bold text-secondary">
                                    <template v-if="langchoose === 'id'">
                                        Gratis *
                                    </template>
                                    <template v-else>
                                        Free *
                                    </template>
                                </span>
                                <span class="margin-top-1 font10rem font-italic text-secondary">
                                    <template v-if="langchoose === 'id'">
                                        *) Syarat ketentuan berlaku.
                                    </template>
                                    <template v-else>
                                        *) Term and conditions applied.
                                    </template>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end-->
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'PricingComponent',
  components: 
  {
  },
  data() {
      return {
        imgContent01 : window.origin+'/img/undraw_Book_lover_re_rwjy.png',
        imgContent02 : window.origin+'/img/undraw_Programming_re_kg9v.png',
        imgContent03 : window.origin+'/img/sd-img.png',
        imgContent04 : window.origin+'/img/smp-img.png',
        imgContent05 : window.origin+'/img/undraw_Educator_re_ju47.png',        
        imgContent06 : window.origin+'/img/undraw_Speech_to_text_re_8mtf.png',        
        lang : localStorage.getItem('lang'),
        langchoose : '',
      }
  },
  mounted() {
    if(this.lang != 'en') {
        this.langchoose = 'id'
    }
    else
    {
        this.langchoose = 'en'
    }
  },
  methods : {
  }        
}
</script>

<style scoped>
    .wrap-border-blue-dark {
        background: linear-gradient(rgba(41, 98, 168, 1), rgba(255, 255, 255, 1));
    }  
    .wrap-border-red-dark {
        background: linear-gradient(rgba(41, 98, 168, 1), rgba(255, 98, 168, 1));
    }  
</style>