<template>
    <div class="relative-pos w-100-ps flex-box flexcol">
        <div class="relative-pos grow-custom-1 w-100-ps wmax-90 flex-box flexcol padding-top-1 padding-bottom-1">
          <div class="relative-pos grow-custom-1 w-100-ps flex-box flexrow">
              <div class="w-100-ps flex-box flexcol ai-start">
                  <div class="flex-box flexcol margin-bottom-05">
                      <div class="wmax-30 relative-pos flexnone-box flexrow padding-bottom-1 serif-fnt font-bold font-dark-550 wrap-content-yes">
                        <h3 class="letterspace15 margin-right-05">
                            <template v-if="langchoose === 'id'">
                                Matematika,
                            </template>
                            <template v-else>
                                Math,
                            </template>
                        </h3>
                        <h3 class="letterspace15 margin-right-05">
                            <template v-if="langchoose === 'id'">
                                Bahasa Inggris,
                            </template>
                            <template v-else>
                                English,
                            </template>
                        </h3>
                        <h3 class="letterspace15 margin-right-05">
                            <template v-if="langchoose === 'id'">
                                MS. Word,
                            </template>
                            <template v-else>
                                MS. Word,
                            </template>
                        </h3>
                        <h3 class="letterspace15 margin-right-05">
                            <template v-if="langchoose === 'id'">
                                MS. Excell,
                            </template>
                            <template v-else>
                                MS. Excell,
                            </template>
                        </h3>
                        <h3 class="letterspace15 margin-right-05">
                            <template v-if="langchoose === 'id'">
                                MS. Powerpoint,
                            </template>
                            <template v-else>
                                MS. Powerpoint,
                            </template>
                        </h3>
                        <h3 class="letterspace15 margin-right-05">
                            <template v-if="langchoose === 'id'">
                                HTML, CSS, VueJS,
                            </template>
                            <template v-else>
                                HTML, CSS, VueJS,
                            </template>
                        </h3>
                        <h3 class="letterspace15 margin-right-05">
                            <template v-if="langchoose === 'id'">
                                PHP, MySQL,
                            </template>
                            <template v-else>
                                PHP, MySQL,
                            </template>
                        </h3>
                        <h3 class="letterspace15 margin-right-05">
                            <template v-if="langchoose === 'id'">
                                Laravel, Lumen, RESTful API.
                            </template>
                            <template v-else>
                                Laravel, Lumen, RESTful API.
                            </template>
                        </h3>
                      </div>
                      <div class="relative-pos noneflex-box flexrow ai-center jc-center padding-bottom-1 wrap-content-yes">
                        <h3 class="letterspace15 margin-right-05">
                            <template v-if="langchoose === 'id'">
                                Matematika,
                            </template>
                            <template v-else>
                                Math,
                            </template>
                        </h3>
                        <h3 class="letterspace15 margin-right-05">
                            <template v-if="langchoose === 'id'">
                                Bahasa Inggris,
                            </template>
                            <template v-else>
                                English,
                            </template>
                        </h3>
                        <h3 class="letterspace15 margin-right-05">
                            <template v-if="langchoose === 'id'">
                                MS. Word,
                            </template>
                            <template v-else>
                                MS. Word,
                            </template>
                        </h3>
                        <h3 class="letterspace15 margin-right-05">
                            <template v-if="langchoose === 'id'">
                                MS. Excell,
                            </template>
                            <template v-else>
                                MS. Excell,
                            </template>
                        </h3>
                        <h3 class="letterspace15 margin-right-05">
                            <template v-if="langchoose === 'id'">
                                MS. Powerpoint,
                            </template>
                            <template v-else>
                                MS. Powerpoint,
                            </template>
                        </h3>
                        <h3 class="letterspace15 margin-right-05">
                            <template v-if="langchoose === 'id'">
                                HTML, CSS, VueJS,
                            </template>
                            <template v-else>
                                HTML, CSS, VueJS,
                            </template>
                        </h3>
                        <h3 class="letterspace15 margin-right-05">
                            <template v-if="langchoose === 'id'">
                                PHP, MySQL,
                            </template>
                            <template v-else>
                                PHP, MySQL,
                            </template>
                        </h3>
                        <h3 class="letterspace15 margin-right-05">
                            <template v-if="langchoose === 'id'">
                                Laravel, Lumen,
                            </template>
                            <template v-else>
                                Laravel, Lumen,
                            </template>
                        </h3>
                        <h3 class="letterspace15 margin-right-05">
                            <template v-if="langchoose === 'id'">
                                RESTful API.
                            </template>
                            <template v-else>
                                RESTful API.
                            </template>
                        </h3>
                      </div>
                  </div>
              </div>
          </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'HeroTxtComponent',
  components: 
  {
  },
  data() {
      return {
        lang : localStorage.getItem('lang'),
        langchoose : '',
      }
  },
  mounted() {
    if(this.lang != 'en') {
        this.langchoose = 'id'
    }
    else
    {
        this.langchoose = 'en'
    }
  },
  methods : {
  }        
}
</script>