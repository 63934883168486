<template>
    <div class="wmax-90 w-100-ps flex-box flexcol padding-left-1 padding-right-1 index-z-9999">
        <div class="grow-custom-1 flex-box flexrow wrap-content-no">
            <div class="flex-box flexrow-col">
                <div class="flexnone-box flexrow ai-center jc-center">
                    <h1 class="font-bold font-dark-50 letterspace15 margin-right-05">
                        SMART
                    </h1>
                    <h3 class="font-bold text-blue-07 letterspace15 border-bottom border-5 border-warning padding-custom-05">
                        <template v-if="lang != 'en'">
                            Kursus
                        </template>
                        <template v-else>
                            Courses
                        </template>
                    </h3>
                </div>
                <div class="noneflex-box flexrow ai-center jc-center">
                    <h1 class="font-bold font-dark-50 letterspace15 margin-right-05">
                        SMART
                    </h1>
                    <h3 class="font-bold text-blue-07 letterspace15 border-bottom border-3 border-warning padding-custom-05">
                        <template v-if="lang != 'en'">
                            Kursus
                        </template>
                        <template v-else>
                            Courses
                        </template>
                    </h3>
                </div>
            </div>
            <div class="grow-custom-1 flexnone-box flexrow ai-center jc-end wrap-content-yes">&nbsp;
            </div>
            <div class="flexnone-box flexrow ai-center jc-center margin-left-1 margin-right-1">
                <template v-if="lang != 'en'">
                    <span @click="changeLang('id')" class="font-bold text-blue-07 margin-right-05 handCursor">ID</span>| 
                    <span @click="changeLang('en')" class="text-secondary margin-left-05 handCursor">EN</span>
                </template>
                <template v-else>
                    <span @click="changeLang('id')" class="text-secondary margin-right-05 handCursor">ID</span>| 
                    <span @click="changeLang('en')" class="font-bold text-blue-07 margin-left-05 handCursor">EN</span>
                </template>
            </div>
            <div @click="chatAdmin" class="margin-left-auto noneflex-box flexrow ai-center jc-center handCursor">
                <h3 class="text-success font-bold margin-right-05">
                    <i class="bi bi-whatsapp"></i>
                </h3>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'HeaderComponent',
        components:
        {
        },
        data() {
            return {
                lang : localStorage.getItem('lang'),
                langchoose : '',
                contactperson : '+6283167836090',
                contactperson02 : '+6285702607484',
            }
        },
        mounted() {
            if(this.lang != 'en') {
                this.langchoose = 'id'
            }
            else
            {
                this.langchoose = 'en'
            }
        },
        methods : {
            changeLang(lang){
                localStorage.setItem("lang", lang)
                this.langchoose = lang
                this.loadedOnce()
            },
            loadedOnce(){
                localStorage.removeItem('reloaded');
                if (localStorage.getItem('reloaded')) {
                    localStorage.removeItem('reloaded');
                } else {
                    localStorage.setItem('reloaded', '1');
                    location.reload();
                }
            },
            chatAdmin(){
                let chatadmin = "https://wa.me/"+this.contactperson02
                window.open(chatadmin, '_blank')
            },
        }        
    }
</script>