<template>
    <div class="relative-pos grow-custom-1 flex-box flexcol">
        <div class="relative-pos flex-box flexcol margin-bottom-3 padding-top-1 padding-bottom-1">
            <HeroBigComponentVue />
        </div>
        <div class="relative-pos flex-box flexcol padding-bottom-2 ai-center">
            <ScheduleComponentVue />
        </div>
        <div class="relative-pos flex-box flexcol padding-bottom-2 ai-center">
            <PaketComponentVue />
        </div>
        <div class="relative-pos flex-box flexcol padding-bottom-2 ai-center">
            <PricingComponentVue />
        </div>
        <!--
        <div class="relative-pos flex-box flexcol ai-center">
            <MetodeComponentVue />
        </div>
        -->
    </div>
</template>

<script>
    import HeroBigComponentVue from './HeroBigComponent.vue';
    import PaketComponentVue from './PaketComponent.vue';
    //import MetodeComponentVue from './MetodeComponent.vue';
    import PricingComponentVue from './PricingComponent.vue';
    import ScheduleComponentVue from './ScheduleComponent.vue';

    export default {
    name: 'HomeComponent',
    components: 
    {
        HeroBigComponentVue,
        PaketComponentVue,
        //MetodeComponentVue,
        PricingComponentVue,
        ScheduleComponentVue,
    },
    data() {
        return {
            lang : localStorage.getItem('lang'),
            langchoose : '',
        }
    },
    mounted() {
        if(this.lang != 'en') {
            this.langchoose = 'id'
        }
        else
        {
            this.langchoose = 'en'
        }
    },
    methods : {
    }        
    }
</script>