<template>
    <div class="relative-pos w-100 flex-box flexcol ai-center jc-center">
        <div class="relative-pos wmax-90 w-100-ps flexnone-box padding-custom-1 flexrow wrap-content-yes">
            <div class="first w-50-ps flex-box flexcol ai-center jc-center padding-left-1 padding-right-1">
                <HeroTxtComponentVue />
            </div>
            <div class="second w-50-ps relative-pos flex-box flexrow ai-center jc-center padding-custom-2">
                <img :src="imgHero02" class="w-100 ocy-img-08-no-hover bounce-out-down rounded-circle shadow" alt="img-hero">
            </div>
        </div>
        <div class="relative-pos wmax-90 w-100-ps noneflex-box flexrow wrap-content-yes">
            <div class="w-50-ps flex-box flexcol padding-custom-1">
                <HeroTxtComponentVue />
            </div>
            <div class="first w-50-ps relative-pos flex-box flexcol ai-center jc-center padding-custom-05">
                <img :src="imgHero02" class="w-100 ocy-img-08-no-hover bounce-out-down rounded-circle shadow" alt="img-hero">
            </div>
        </div>
    </div>
</template>

<script>
    import HeroTxtComponentVue from './HeroTxtComponent.vue';

    export default {
        name: 'HeroBigComponent',
        components: 
        {
            HeroTxtComponentVue,
        },
        data() {
            return {
                imgHero01 : window.origin+'/img/banner-tb.png',
                imgHero02 : window.origin+'/img/undraw_laravel_and_vue_59tpxx.jpg',
            }
        },
        mounted() {
        },
        methods : {
        }        
    }
</script>

<style scoped>
    .first {
        order: 1;
    }
    .second {
        order: 2;
    }
</style>