import { createRouter, createWebHistory } from 'vue-router';

//--error--//
import ErrorPage from '@/components/errors/ErrorPage.vue';

//--base--//
import HomeComponent from '@/components/home/HomeComponent.vue';
//import CourseClassComponent from '@/components/home/CourseClassComponent.vue';
//import PricingComponent from '@/components/home/PricingComponent.vue';
//import ContactComponent from '@/components/home/ContactComponent.vue';

//--routes--//
const routes = [
    {
        path: '/',
        component: HomeComponent,
    },
    /*
    {
        path: '/course-class',
        component: CourseClassComponent,
    },
    {
        path: '/pricing',
        component: PricingComponent,
    },
    {
        path: '/contact',
        component: ContactComponent,
    },
    */
    {
        path: '/error',
        component: ErrorPage
    },  
    {
        path: '/:pathMatch(.*)*', 
        redirect: '/error'
    },    

]
//--end--//

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router