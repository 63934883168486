const MyConfig = {
    install(app) {
        app.config.globalProperties.$appname = 'SmartBiz';
        app.config.globalProperties.$myLogo = window.origin+'/img/bmlogo.png';
        app.config.globalProperties.$processingmsg = 'PROCESSING. Please Wait';
        app.config.globalProperties.$500msg = 'Internal Server Error';
        app.config.globalProperties.$404msg = 'Page Not Found';
        app.config.globalProperties.$unknownerr = 'Call Administrator';
        app.config.globalProperties.$idcode = '+62';
    },
}
export default MyConfig