<template>
    <div class="wmax-90 w-100-ps grow-custom-1 relative-pos flex-box flexcol ai-center jc-center">
        <div class="w-100 flex-box flexcol ai-center jc-center margin-bottom-1 padding-top-1">
            <div class="flex-box flexrow">
                <template v-if="langchoose === 'id'">
                    <h1 class="margin-right-05 font-bold letterspce15 text-secondary padding-left-020 padding-right-020 padding-top-020 padding-bottom-020">
                        Kursus
                    </h1>
                    <h1 class="clr-blue-07 font-bold letterspce15 text-white padding-left-020 padding-right-020 padding-top-020 padding-bottom-020">
                        Privat
                    </h1>
                </template>
                <template v-else>
                    <h1 class="clr-blue-07 font-bold letterspce15 text-white padding-left-020 padding-right-020 padding-top-020 padding-bottom-020">
                        Private
                    </h1>
                    <h1 class="margin-right-05 font-bold letterspce15 text-secondary padding-left-020 padding-right-020 padding-top-020 padding-bottom-020">
                        Courses
                    </h1>
                </template>
            </div>
            <h5 class="text-secondary text-center">
                <template v-if="langchoose === 'id'">
                    Daring & Tatap Muka
                </template>
                <template v-else>
                    Online and Offline
                </template>
            </h5>
        </div>
        <div class="w-100 flex-box flexcol ai-center jc-center margin-bottom-1 padding-custom-05">
            <div class="w-100 flex-box flexrow-col jc-between wrap-content-yes">
                <div class="w-25-ps flex-box flexcol ai-center jc-center padding-custom-1">
                    <div class="clr-blue-07 text-secondary
                                    grow-custom-1 
                                    w-100 
                                    flex-box flexcol 
                                    ai-center jc-center
                                    padding-left-010
                                    padding-right-010
                                    padding-top-010
                                    padding-bottom-1 
                                    rounded-custom-25">
                        <div class="bs22-white grow-custom-1 w-100 flex-box flexcol padding-custom-1 rounded-custom-25">
                            <div class="margin-bottom-05 flex-box flexcol">
                                <h4 class="font-bold text-secondary letterspce15">
                                    <template v-if="langchoose === 'id'">
                                        SD, SMP, & SMA
                                    </template>
                                    <template v-else>
                                        Elementary and High Schools
                                    </template>
                                </h4>
                            </div>
                            <div class="margin-bottom-05 flex-box flexcol">
                                <ul class="padding-left-0 style-list-none">
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05"></i>
                                        <template v-if="langchoose === 'id'">
                                            Matematika
                                        </template>
                                        <template v-else>
                                            Math
                                        </template>
                                    </li>
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05"></i>
                                        <template v-if="langchoose === 'id'">
                                            Bahasa Inggris
                                        </template>
                                        <template v-else>
                                            English
                                        </template>
                                    </li>
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05"></i>
                                        <template v-if="langchoose === 'id'">
                                            Mapel Lainnya
                                        </template>
                                        <template v-else>
                                            Other Subjects
                                        </template>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-25-ps flex-box flexcol ai-center jc-center padding-custom-1">
                    <div class="clr-blue-07 text-secondary
                                    grow-custom-1 
                                    w-100 
                                    flex-box flexcol 
                                    ai-center jc-center
                                    padding-left-010
                                    padding-right-010
                                    padding-top-010
                                    padding-bottom-1 
                                    rounded-custom-25">
                        <div class="bs22-white grow-custom-1 w-100 flex-box flexcol padding-custom-1 rounded-custom-25">
                            <div class="margin-bottom-05 flex-box flexcol">
                                <h4 class="font-bold text-secondary letterspce15">
                                    <template v-if="langchoose === 'id'">
                                        Komputer
                                    </template>
                                    <template v-else>
                                        Computer
                                    </template>
                                </h4>
                            </div>
                            <div class="margin-bottom-05 flex-box flexcol">
                                <ul class="padding-left-0 style-list-none">
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05"></i>
                                        <template v-if="langchoose === 'id'">
                                            Microsoft Windows
                                        </template>
                                        <template v-else>
                                            Microsoft Windows
                                        </template>
                                    </li>
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05"></i>
                                        <template v-if="langchoose === 'id'">
                                            Microsoft Office
                                        </template>
                                        <template v-else>
                                            Microsoft Office
                                        </template>
                                    </li>
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05"></i>
                                        <template v-if="langchoose === 'id'">
                                            Internet
                                        </template>
                                        <template v-else>
                                            Internet
                                        </template>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-25-ps flex-box flexcol ai-center jc-center padding-custom-1">
                    <div class="clr-blue-07 text-secondary
                                    grow-custom-1 
                                    w-100 
                                    flex-box flexcol 
                                    ai-center jc-center
                                    padding-left-010
                                    padding-right-010
                                    padding-top-010
                                    padding-bottom-1 
                                    rounded-custom-25">
                        <div class="bs22-white grow-custom-1 w-100 flex-box flexcol padding-custom-1 rounded-custom-25">
                            <div class="margin-bottom-05 flex-box flexcol">
                                <h4 class="font-bold text-secondary letterspce15">
                                    <template v-if="langchoose === 'id'">
                                        Pemrograman
                                    </template>
                                    <template v-else>
                                        Programming
                                    </template>
                                </h4>
                            </div>
                            <div class="margin-bottom-05 flex-box flexcol">
                                <ul class="padding-left-0 style-list-none">
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05"></i>
                                        <template v-if="langchoose === 'id'">
                                            HTML, CSS, VueJS
                                        </template>
                                        <template v-else>
                                            HTML, CSS, VueJS
                                        </template>
                                    </li>
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05"></i>
                                        <template v-if="langchoose === 'id'">
                                            PHP
                                        </template>
                                        <template v-else>
                                            PHP
                                        </template>
                                    </li>
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05"></i>
                                        <template v-if="langchoose === 'id'">
                                            MySQL
                                        </template>
                                        <template v-else>
                                            MySQL
                                        </template>
                                    </li>
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05"></i>
                                        <template v-if="langchoose === 'id'">
                                            Laravel, Lumen
                                        </template>
                                        <template v-else>
                                            Laravel, Lumen
                                        </template>
                                    </li>
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05"></i>
                                        <template v-if="langchoose === 'id'">
                                            RESTful API
                                        </template>
                                        <template v-else>
                                            RESTful API
                                        </template>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-25-ps flex-box flexcol ai-center jc-center padding-custom-1">
                    <div class="clr-blue-07 text-secondary
                                    grow-custom-1 
                                    w-100 
                                    flex-box flexcol 
                                    ai-center jc-center
                                    padding-left-010
                                    padding-right-010
                                    padding-top-010
                                    padding-bottom-1 
                                    rounded-custom-25">
                        <div class="bs22-white grow-custom-1 w-100 flex-box flexcol padding-custom-1 rounded-custom-25">
                            <div class="margin-bottom-05 flex-box flexcol">
                                <h4 class="font-bold text-secondary letterspce15">
                                    <template v-if="langchoose === 'id'">
                                        Konsultasi
                                    </template>
                                    <template v-else>
                                        Consultation
                                    </template>
                                </h4>
                            </div>
                            <div class="margin-bottom-05 flex-box flexcol">
                                <ul class="padding-left-0 style-list-none">
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05"></i>
                                        <template v-if="langchoose === 'id'">
                                            Tugas Sekolah
                                        </template>
                                        <template v-else>
                                            School tasks
                                        </template>
                                    </li>
                                    <li class="margin-bottom-05 flex-box flexrow">
                                        <i class="bi bi-check margin-right-05"></i>
                                        <template v-if="langchoose === 'id'">
                                            Tugas Kuliah
                                        </template>
                                        <template v-else>
                                            College tasks
                                        </template>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'PaketComponent',
  components: 
  {
  },
  data() {
      return {
        imgContent01 : window.origin+'/img/class-roomxx.jpg',
        imgContent02 : window.origin+'/img/teacherxx.jpg',
        imgContent03 : window.origin+'/img/codingerxx.jpg',
        imgContent04 : window.origin+'/img/team-workxx.jpg',
        lang : localStorage.getItem('lang'),
        langchoose : '',
      }
  },
  mounted() {
    if(this.lang != 'en') {
        this.langchoose = 'id'
    }
    else
    {
        this.langchoose = 'en'
    }
  },
  methods : {
  }        
}
</script>

<style scoped>
    .wrap-border-blue-dark {
        background: linear-gradient(rgba(41, 98, 168, 1), rgba(255, 255, 255, 1));
    }  
    .wrap-border-red-dark {
        background: linear-gradient(rgba(41, 98, 168, 1), rgba(255, 98, 168, 1));
    }  
</style>