<template>
    <div class="w-100-ps relative-pos flex-box flexcol bg-white">
        <div class="bg-white sticky top-0 index-z-999 w-100 h-75-px flex-box flexcol ai-center jc-center">
            <HeaderComponentVue />
        </div>
        <div class="margin-top-auto relative-pos flex-box flexcol">
            <router-view></router-view>
        </div>
        <div class="relative-pos flex-box flexcol ai-center jc-center bs22-gray-100">
            <FooterComponentVue />
        </div>
    </div>
</template>

<script>
    import FooterComponentVue from '@/components/home/FooterComponent.vue';
    import HeaderComponentVue from '@/components/home/HeaderComponent.vue';
    //import TopSeparatorComponentVue from '@/components/home/TopSeparatorComponent.vue';

    export default {
        name: 'App',
        components: 
        {
            FooterComponentVue,
            HeaderComponentVue,
            //TopSeparatorComponentVue,
        },
        data() {
            return {
                myLogo : window.origin+'/img/bmlogo.png',
            }
        },
        methods : {
        }        
    }
</script>

<style scoped>
    .sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }
</style>