<template>
    <div class="w-100-ps relative-pos flex-box flexcol ai-center jc-center">
        <div class="grow-custom-1 wmax-90 w-100-ps flex-box flexrow-col ai-start padding-custom-1">
            <div class="margin-bottom-1 grow-custom-1 h-100-ps flex-box flexcol padding-left-1 padding-right-1 ai-start">
                <div class="flex-box flexcol">
                    <h1 class="text-blue-07 font-bold letterspace15">
                        Teman Belajar
                    </h1>
                    <div class="text-secondary flex-box flexrow">
                        <span class="padding-right-05">
                            <template v-if="langchoose === 'id'">
                                Tamanmartani, Kalasan, Sleman, Yogyakarta.
                            </template>
                            <template v-else>
                                Tamanmartani, Kalasan, Sleman, Yogyakarta
                            </template>
                        </span>
                    </div>
                    <div @click="chatAdmin(contactperson)" class="text-secondary flex-box flexrow ai-center padding-top-05 handCursor">
                        <i class="bi bi-whatsapp margin-right-05"></i>
                        <span class="padding-right-05">
                            {{ contactperson }}
                        </span>
                        <span class="text-secondary">(chat only)</span>
                    </div>
                    <div @click="chatAdmin(contactperson02)" class="text-secondary flex-box flexrow ai-center padding-top-05 padding-bottom-05 handCursor">
                        <i class="bi bi-whatsapp margin-right-05"></i>
                        <span class="padding-right-05">
                            {{ contactperson02 }}
                        </span>
                        <span class="text-secondary">(chat only)</span>
                    </div>
                    <div class="margin-top-1 text-secondary flexnone-box flexrow">
                        <span class="padding-right-05">
                            ©{{ yearnow }}. All rights reserved.
                        </span>
                    </div>
                </div>
            </div>
            <div class="margin-bottom-1 grow-custom-1 h-100-ps flex-box flexcol padding-left-1 padding-right-1 ai-start text-secondary">
                <ul class="padding-left-0 style-list-none">
                    <li class="margin-bottom-05 flex-box flexrow">
                        <i class="bi bi-check margin-right-05"></i>
                        <template v-if="langchoose === 'id'">
                            Matematika, Bahasa Inggris, & Lainnya
                        </template>
                        <template v-else>
                            Math, English, etc
                        </template>
                    </li>
                    <li class="margin-bottom-05 flex-box flexrow">
                        <i class="bi bi-check margin-right-05"></i>
                        <template v-if="langchoose === 'id'">
                            Komputer Dasar
                        </template>
                        <template v-else>
                            Basic Computer
                        </template>
                    </li>
                    <li class="margin-bottom-05 flex-box flexrow">
                        <i class="bi bi-check margin-right-05"></i>
                        <template v-if="langchoose === 'id'">
                            Frontend (HTML, CSS, & VueJS)
                        </template>
                        <template v-else>
                            Frontend (HTML, CSS, & VueJS)
                        </template>
                    </li>
                    <li class="margin-bottom-05 flex-box flexrow">
                        <i class="bi bi-check margin-right-05"></i>
                        <template v-if="langchoose === 'id'">
                            Backend (PHP, MySQL, Laravel, Lumen, & RESTful API)
                        </template>
                        <template v-else>
                            Backend (PHP, MySQL, Laravel, Lumen, & RESTful API)
                        </template>
                    </li>
                </ul>
                <div class="noneflex-box flexrow padding-top-05 padding-bottom-05">
                    <h1 class="margin-right-1 text-danger">
                        <i class="bi bi-youtube"></i>
                    </h1>
                    <h1 class="text-danger">
                        <i class="bi bi-instagram"></i>
                    </h1>
                </div>
            </div>
            <div class="grow-custom-1 h-100-ps flex-box flexcol padding-left-1 padding-right-1 ai-end">
                <div class="flexnone-box flexrow">
                    <h1 class="margin-right-1 text-danger">
                        <i class="bi bi-youtube"></i>
                    </h1>
                    <h1 class="text-danger">
                        <i class="bi bi-instagram"></i>
                    </h1>
                </div>
                <div class="margin-top-1 text-secondary noneflex-box flexrow">
                    <span class="padding-right-2">
                        ©{{ yearnow }}. All rights reserved.
                    </span>
                    <div class="margin-left-auto noneflex-box flexrow ai-center">
                        <template v-if="lang != 'en'">
                            <span @click="changeLang('id')" class="font-bold text-primary margin-right-05 handCursor">ID</span>| 
                            <span @click="changeLang('en')" class="text-secondary margin-left-05 handCursor">EN</span>
                        </template>
                        <template v-else>
                            <span @click="changeLang('id')" class="text-secondary margin-right-05 handCursor">ID</span>| 
                            <span @click="changeLang('en')" class="font-bold text-primary margin-left-05 handCursor">EN</span>
                        </template>
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FooterComponent',
        data() {
            return {
                yearnow : '',
                lang : localStorage.getItem('lang'),
                langchoose : '',
                contactperson : '+6283167836090',
                contactperson02 : '+6285702607484',
            }
        },
        mounted() {
            this.currentYear();
            if(this.lang != 'en') {
                this.langchoose = 'id'
            }
            else
            {
                this.langchoose = 'en'
            }
        },
        computed: {
        },        
        methods : {
            currentYear() {
                let tglnow = new Date()
                this.yearnow = tglnow.getFullYear()
            },            
            changeLang(lang){
                localStorage.setItem("lang", lang)
                this.langchoose = lang
                this.loadedOnce()
            },
            loadedOnce(){
                localStorage.removeItem('reloaded');
                if (localStorage.getItem('reloaded')) {
                    localStorage.removeItem('reloaded');
                } else {
                    localStorage.setItem('reloaded', '1');
                    location.reload();
                }
            },
            chatAdmin(wanumber){
                let chatadmin = "https://wa.me/"+wanumber
                window.open(chatadmin, '_blank')
            },
        }        
    }
</script>